import { createRouter, createWebHistory } from 'vue-router';
import { storage } from '@/utils/storage';
import { useUserStore } from '@/store'
import { useHelpTool } from '@/hooks'

const { showLoginDialog } = useHelpTool()

const routes = [
  {
    name: 'notFound',
    path: '/:path(.*)+',
    redirect: {
      name: 'game',
    },
  },
  {
    name: 'login',
    path: '/login',
    component: () => import('./view/user/login'),
  },
  {
    name: 'register',
    path: '/register',
    component: () => import('./view/user/register'),
  },
  {
    name: 'forgetPassword',
    path: '/forgetPassword',
    component: () => import('./view/user/forgetPassword'),
  },
  {
    name: 'tab',
    path: '/tab',
    component: () => import('./layout/TabbarLayout'),
    children:[{
      name: 'game',
      path: '/game',
      component: () => import('./view/game/index'),
    },
    {
      name: 'energy',
      path: '/energy',
      component: () => import('./view/inviteFriends/index'),
    },
    {
      name: 'helpSlot',
      path: '/helpSlot',
      component: () => import('./view/helpSlot/index'),
    },
    {
      name: 'event',
      path: '/event',
      component: () => import('./view/promotion/index'),
    }]
  },
  {
    name: 'gameSearchHistory',
    path: '/game/search',
    component: () => import('./view/game/searchHistory'),
  },
  {
    name: 'gameSearchResult',
    path: '/game/search/result',
    component: () => import('./view/game/searchResult'),
  },
  {
    name: 'task',
    path: '/task',
    component: () => import('./view/task/index'),
  }, 
  {
    name: 'profile',
    path: '/profile',
    component: () => import('./view/user/profile'),
  },
  {
    name: 'setting',
    path: '/setting',
    component: () => import('./view/setting/index'),
  },
  {
    name: 'withdraw',
    path: '/finance/withdraw',
    component: () => import('./view/finance/withdraw'),
  },
  {
    name: 'recharge',
    path: '/finance/recharge',
    component: () => import('./view/finance/deposit'),
  },
  {
    name: 'exchange',
    path: '/finance/exchange',
    component: () => import('./view/finance/exchange'),
  },
  {
    name: 'transactionHistory',
    path: '/transactionHistory',
    component: () => import('./view/finance/record'),
  },
  {
    name: 'records',
    path: '/records',
    component: () => import('./view/records/index'),
  },
  {
    name: 'livechat',
    path: '/livechat',
    component: () => import('./view/livechat/index'),
  },
  {
    name: 'vip',
    path: '/vip',
    component: () => import('./view/vip/index'),
  },
  // {
  //   name: 'vipRule',
  //   path: '/vip/rule',
  //   component: () => import('./view/vip/rule'),
  // },
  {
    name: 'securityCenter',
    path: '/security-center',
    component: () => import('./view/user/securityCenter'),
  },
  {
    name: 'inbox',
    path: '/inbox',
    component: () => import('./view/inbox/index'),
  },
  {
    name: 'redeem',
    path: '/redeem',
    component: () => import('./view/redeem/index'),
  },
  {
    name: 'invite',
    path: '/inviteBonus',
    component: () => import('./view/inviteBonus/index'),
  },
  {
    name: 'inviteBonusRule',
    path: '/inviteBonus/rule',
    component: () => import('./view/inviteBonus/rule'),
  },
  {
    name: 'signUp',
    path: '/signUpBonus',
    component: () => import('./view/signUpBonus/index'),
  },
];

const router = createRouter({
  routes,
  history: createWebHistory(),
});

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  if (!storage.get('userInfo')
    && to.name !== 'login' 
    && to.name !== 'register' 
    && to.name !== 'forgetPassword'
    && to.name !== 'game'
    && to.name !== 'gameSearchHistory'
    && to.name !== 'gameSearchResult'
    && to.name !== 'livechat'
  ) {
    next({ name: 'game' });
    showLoginDialog();
    return;
  }
  // 进入提现页面前 未设置提现密码跳转到安全中心
  if (to.name === 'withdraw' && useUserStore().isNullPasswordTrade) {
    next({ name: 'securityCenter', query: { code: 'transactionPwd' } });
    return;
  }
  next();
});

export { router };
